<template>
  <div class="pdfreader">
    <iframe
      :src="src"
      width="100%"
      height="100%"
      frameborder="0"
    />
  </div>
</template>

<script>
export default {
  name: 'PDFReader',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
iframe {
  display: block;
}
.pdfreader {
  background: #525659;
  height: calc(100vh - 72px);
  margin-top: 72px;
}
</style>
